import eventbus from "gia/eventbus";

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import toggle_class from "../../../utilities/toggle_class";

// ****************************************************************************** //
// ****************************************************************************** //

function create_subitem(ID_parent, ITEMELEMENT) {
  const id = ITEMELEMENT.dataset.id;
  const subItem = ITEMELEMENT;
  const is_togglable = subItem.hasAttribute("data-is-togglable");
  const is_active = subItem.hasAttribute("data-is-active");
  const toggleBtnEl = is_togglable ? document.getElementById(`toggleBtn_forItem_${id}`) : null;
  const costLabelEl = document.getElementById(`costLabel_forItem_${id}`);
  const effortLabelEl = document.getElementById(`effortLabel_forItem_${id}`);

  const costLabel = ($$$) =>
    `<span style="display: flex">
      ${$$$ <= 9999.99 && $$$ > 99.99 ? "&nbsp;" : ""}
      ${$$$ <= 99.99 && $$$ > 9.99 ? "&nbsp;&nbsp;" : ""}
      ${$$$ <= 9.99 ? "&nbsp;&nbsp;&nbsp;" : ""}
      ${$$$.toFixed(2)}
    </span>`;

  ///////////////////////////////////////////////////////
  // Set ID, active-state & config. getters/setters... //
  ///////////////////////////////////////////////////////

  const item = {
    context: subItem.dataset.context,
    id: id,
    id_parent: ID_parent,
    title: subItem.dataset.title,
    route: this.options.route,
    //////
    active: is_active ? true : false,
    interactions: true,
    is_subItem: true,
    //////
    el: subItem,
    costLabelEl: costLabelEl,
    effortLabelEl: effortLabelEl,
    toggleButton: toggleBtnEl,
    //////
    subItems: null,
    //////
    cost: parseFloat(subItem.dataset.cost),
    effort: parseFloat(subItem.dataset.effort),

    /////////////
    // Getters //
    /////////////

    get totalCost() {
      return this.cost;
    },

    get totalEffort() {
      return this.effort;
    },

    /////////////
    // Setters //
    /////////////

    set setId(ID_new) {
      this.id = ID_new;
      this.el.setAttribute("data-id", ID_new);
    },

    set setParentId(ID_parent_new) {
      this.id_parent = ID_parent_new;
    },

    set setActive({ activeState }) {
      this.active = activeState;

      //////////////////////////////////////
      // Updt. item & button el. style... //
      //////////////////////////////////////

      toggle_class(this.el, "QuoteItem__item_state_inactive", null, !this.active);

      if (is_togglable) {
        toggle_class(this.toggleButton, "Button_state_active", null, this.active);
      }

      /////////////////////
      // Updt. parent... //
      /////////////////////

      this.updtParentCostEffort();
      this.updtParentActiveState();

      //////////////////////////////////////
      // Updt. quote total cost/effort... //
      //////////////////////////////////////

      eventbus.emit("quote_itemCostUpdt");
      eventbus.emit("quote_itemEffortUpdt");
    },

    set setInteractions(interactionsState) {
      this.interactions = interactionsState;
      toggle_class(this.toggleButton, "Button_state_disabled", null, !this.interactions);
    },

    set setCost(COST_new) {
      this.cost = COST_new;
      this.costLabelEl.innerHTML = costLabel(COST_new > 0 ? COST_new : this.totalCost);
    },

    set setEffort(EFFORT_new) {
      this.effort = EFFORT_new;
      this.effortLabelEl.innerHTML = costLabel(EFFORT_new > 0 ? EFFORT_new : this.totalEffort);
    },
  };

  ///////////////////////////////////////////
  // Add click-handler to toggle button... //
  ///////////////////////////////////////////

  if (is_togglable) {
    toggleBtnEl.addEventListener("click", () => {
      item.setActive = { activeState: !item.active };

      // Updt. quote item's active state in backend...
      item.setInteractions = false;
      this.updt_backendActiveState(item);
    });
  }

  return item;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_subitem;
