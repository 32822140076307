import Component from "gia/Component";
// import eventbus from "gia/eventbus";
import config from "gia/config";

// ********************************** PACKAGES ********************************** //
// ****************************************************************************** //

import { computePosition, offset } from "@floating-ui/dom";

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// import toggle_class from "../../utilities/toggle_class";
// import toggle_interactions from "../../utilities/toggle_interactions";

// ****************************************************************************** //
// ****************************************************************************** //

class Tooltip extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      logs: {
        runWithLogs: true,
        logStyles: {
          default: "color: #000; background-color: #9999FF",
          success: "color: #000; background-color: #9900FF",
          stateChange: "color: #000; background-color: #9966FF",
        },
      },
      blockName: "Tooltip",
      //////
      target: "defaultValue",
    };

    ///////// Event listeners //////////
    ////////////////////////////////////

    // ...

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Note: global comp. are never unmounted

  unmount() {
    ////////////////////////////////
    // Unregister event listeners //
    ////////////////////////////////
    // ...
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////
    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const refs = this.ref;
    const opts = this.options;
    //////
    this.init_position(opts.target);
    this.init_eventListeners(opts.target);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_position(TARGET) {
    this.update_position(TARGET);
  }

  init_eventListeners(TARGET) {
    const targetEl = document.getElementById(TARGET);
    //////
    [
      ["mouseenter", this.show.bind(this)],
      ["mouseleave", this.hide.bind(this)],
    ].forEach(([event, listener]) => {
      targetEl.addEventListener(event, listener);
    });
  }

  /////////////////////////////// Comp. f() //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  show() {
    this.element.style.opacity = "1";
    this.update_position(this.options.target);
  }

  hide() {
    this.element.style.opacity = "0";
    this.update_position(this.options.target);
  }

  ////////////// Update //////////////
  ////////////////////////////////////

  update_position(TARGET) {
    const tooltipEl = this.element;
    const targetEl = document.getElementById(TARGET);
    //////
    computePosition(targetEl, tooltipEl, {
      placement: "right",
      middleware: [offset(6)],
    }).then(({ x, y }) => {
      Object.assign(tooltipEl.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  //////////////////////////// Event handlers ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // ...

  ////////////////////////// Eventbus Handlers ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // ...

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes, RUN_withLogs = this.options.logs) {
    const refs = this.ref;
    const state = this.state;
    const options = this.options;

    const blockName = options.blockName;

    /////////////// Logs ///////////////
    ////////////////////////////////////

    if (RUN_withLogs) {
      for (let c in changes) {
        log(blockName, ["state change", `${c} ⇒ ${changes[c]}`], `${blockName}_stateChange`);
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default Tooltip;
