// ****************************************************************************** //
// ****************************************************************************** //

const logStyles = {
  default: "color: #000; background-color: #32cd32",
  error: "color: #000; background-color: #FF3366",
  //////
  markup: "color: #32cd32; background-color: #050505",
  trans: "color: #000; background-color: #ee82ee",
};

// ****************************************************************************** //
// ****************************************************************************** //

function log(CONTEXT = "App", SEGMENTS = [], STYLE = logStyles["default"], ENABLED = true) {
  if (ENABLED) {
    let messageString = `%c${CONTEXT} ▶ ${SEGMENTS[0]}`;
    //////
    SEGMENTS.shift();
    SEGMENTS.forEach(
      (s) =>
        (messageString += `
${" ".repeat(CONTEXT.length)} ▶ ${s} `)
    );
    //////
    console.log(messageString, STYLE);
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default log;
