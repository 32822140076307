// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ********************************** PACKAGES ********************************** //
// ****************************************************************************** //

import Swup from "swup";
import SwupGiaPlugin from "@swup/gia-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";

import eventbus from "gia/eventbus";
import loadComponents from "gia/loadComponents";

// ****************************** DOCUMENT/WINDOW ******************************* //
// ****************************************************************************** //

///////////////////////////// Window Events ////////////////////////////
////////////////////////////////////////////////////////////////////////

// ...

// ************************************* GIA ************************************ //
// ****************************************************************************** //

///////////////////////////// Local comp. //////////////////////////////
////////////////////////////////////////////////////////////////////////

import Slider from "./components/Slider/Slider";
import Collapsible from "./components/Collapsible/Collapsible";
import Quote from "./components/Quote/Quote";
import Tooltip from "./components/Tooltip/Tooltip";

const components_local = {
  Slider,
  Collapsible,
  Quote,
  Tooltip,
};

///////////////////////////// Global comp. /////////////////////////////
////////////////////////////////////////////////////////////////////////

const components_global = {
  // ...
};

loadComponents(components_global);

// ************************************ SWUP ************************************ //
// ****************************************************************************** //

const swupOptions = {
  log: false,
  containers: ["#swup"],
  plugins: [
    new SwupGiaPlugin({ components: components_local, log: false }),
    new SwupPreloadPlugin(),
    // new SwupBodyClassPlugin(),
  ],
};

const swupInstance = new Swup(swupOptions);

////////////////////////////// Swup Events /////////////////////////////
////////////////////////////////////////////////////////////////////////

swupInstance.on("clickLink", (e) => {
  const clickedLink = e.delegateTarget;
  const target = clickedLink ? clickedLink.href : null;
  const route = new URL(target).pathname.split("/").filter(Boolean).pop();
  console.log(route);
});

//////////////////////////// Swup Utilities ////////////////////////////
////////////////////////////////////////////////////////////////////////

// ...

// ****************************************************************************** //
// ****************************************************************************** //
