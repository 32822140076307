// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function calc_total(ITEMS, VALUENAME) {
  let total = 0;
  //////
  for (let it of ITEMS) {
    total += it[VALUENAME];
  }
  //////
  return total;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default calc_total;
