// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_textLines from "./print_textLines";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function render_footnotes(DOCUMENT, FOOTNOTES) {
  const { margin, textHeight, pageSize, textWidth } = config;
  //////
  DOCUMENT.setFontSize(config.fontSize_small);
  //////
  const lines0 = DOCUMENT.splitTextToSize(FOOTNOTES[0].text, textWidth * 0.666);
  lines0[0] = `${1}: ${lines0[0]}`;
  print_textLines(
    DOCUMENT,
    lines0,
    {
      x: margin,
      y: pageSize.h - (margin + textHeight * 10),
    },
    undefined,
    "right"
  );
  //////
  const lines1 = DOCUMENT.splitTextToSize(FOOTNOTES[1].text, textWidth * 0.666);
  lines1[0] = `${2}: ${lines1[0]}`;
  print_textLines(
    DOCUMENT,
    lines1,
    {
      x: margin,
      y: pageSize.h - (margin + textHeight * 7),
    },
    undefined,
    "right"
  );
  //////
  const lines2 = DOCUMENT.splitTextToSize(FOOTNOTES[2].text, textWidth * 0.666);
  lines2[0] = `${3}: ${lines2[0]}`;
  print_textLines(
    DOCUMENT,
    lines2,
    {
      x: margin,
      y: pageSize.h - (margin + textHeight * 5),
    },
    undefined,
    "right"
  );
}

// ****************************************************************************** //
// ****************************************************************************** //

export default render_footnotes;
