// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import render_header from "./render_header";
import render_footer from "./render_footer";
import render_contentBlocks from "./render_contentBlocks";
import render_table from "./render_table";
import render_footnotes from "./render_footnotes";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

async function create_pdf(ITEMS, PATH_data) {
  const quotePDF = new jspdf.jsPDF("p", "mm", "a4"); // @72ppi

  /////////////////////////////// Config. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const { margin, fontSize, textHeight, boilerPlates } = config;

  quotePDF.setFontSize(fontSize);
  quotePDF.addFont("/assets/typo/fonts/founders-grotesk-mono-light.ttf", "Founders", "normal");
  quotePDF.setFont("Founders");

  ///////////////////////////// Data fetching ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  const request = await fetch(PATH_data);
  const data = await request.json();

  //////////////////////////////// Header ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  let content = {
    contact: data.contact,
    addressor: data.addressor,
  };
  render_header(quotePDF, content);

  //////////////////////////////// Intro /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  let linesPrinted = 64 / textHeight;
  linesPrinted += render_contentBlocks(quotePDF, data.introBlocks, { x: margin, y: 64 });
  render_footer(quotePDF, 1, 3);

  ///////////////////////// Calculation (Table) //////////////////////////
  ////////////////////////////////////////////////////////////////////////

  linesPrinted = 0;
  quotePDF.addPage();
  render_table(quotePDF, ITEMS, { x: margin, y: margin + textHeight * linesPrinted });
  render_footer(quotePDF, 2, 3);

  //////////////////////////////// Outro /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  linesPrinted = 0;

  quotePDF.addPage();
  linesPrinted += render_contentBlocks(quotePDF, data.outroBlocks, { x: margin, y: margin });
  render_footer(quotePDF, 3, 3);
  render_footnotes(quotePDF, data.footnotes);

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  return quotePDF;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_pdf;
