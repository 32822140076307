import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

// ********************************** PACKAGES ********************************** //
// ****************************************************************************** //

// ...

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import calc_total from "./utilities/calc_total";
import create_itemList from "./utilities/create_itemList";
import create_subitemList from "./utilities/create_subitemList";
import create_item from "./utilities/create_item";
import create_subitem from "./utilities/create_subitem";
import create_totalLabel from "./utilities/create_totalLabel";
import create_pdf from "./utilities/create_pdf/create_pdf";
import updt_backendActiveState from "./utilities/updt_backendActiveState";

import log from "../../utilities/log";
import toggle_class from "../../utilities/toggle_class";
import toggle_interactions from "../../utilities/toggle_interactions";

// ****************************************************************************** //
// ****************************************************************************** //

class Quote extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      items: [],
      totalEffortLabel: null,
      totalCostLabel: null,
      totalTaxLabel: null,
      //////
      btn_pdfDwnld: null,
      btn_updtData: null,
    };
    //////
    this.options = {
      logs: {
        runWithLogs: false,
        logStyles: {
          default: "color: #000; background-color: #9999FF",
          success: "color: #000; background-color: #9900FF",
          stateChange: "color: #000; background-color: #9966FF",
        },
      },
      blockName: "Quote",
      ///
      route: "defaultValue",
      totalCost: "defaultValue",
      salesTax: "defaultValue",
      salesTaxIncluded: "defaultValue",
      path_pdfData: "defaultValue",
    };

    ///////// Event listeners //////////
    ////////////////////////////////////

    this.el_btn_pdfDwnld_click = this.eh_btn_pdfDwnld_click.bind(this);

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_quote_itemCostUpdt = this.ebh_quote_itemCostUpdt.bind(this);
    this.ebl_quote_itemEffortUpdt = this.ebh_quote_itemEffortUpdt.bind(this);
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Note: global comp. are never unmounted

  unmount() {
    ////////////////////////////////
    // Unregister event listeners //
    ////////////////////////////////
    // ...
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////
    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const refs = this.ref;
    const opts = this.options;
    //////
    this.init_states();
    this.init_eventbus();
    this.init_events();
    //////
    this.init_items(refs.items);
    this.init_btn_pdfDwnld(refs.btn_pdfDwnld);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.setState({
      totalCost: parseFloat(this.options.totalCost),
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_events() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    eventbus.on("quote_itemCostUpdt", this.ebl_quote_itemCostUpdt);
    eventbus.on("quote_itemEffortUpdt", this.ebl_quote_itemEffortUpdt);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_mobile() {
    // ...
  }

  init_desktop() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_items(ITEMELEMENTS) {
    ////////////////////////////////////////////////////
    // Create item list from org. set of item el. ... //
    ////////////////////////////////////////////////////

    const itemList = this.create_itemList(ITEMELEMENTS);

    ///////////////////////////
    // ...run item init. ... //
    ///////////////////////////

    for (let item of itemList) {
      item.init();
    }

    ///////////////////////////////
    // ...store entries in state //
    ///////////////////////////////

    this.setState({
      entries: itemList,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_btn_pdfDwnld(BUTTONEL) {
    BUTTONEL.addEventListener("click", this.el_btn_pdfDwnld_click);
  }

  //////////////////////////// Comp. Funtions ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  async dwnld_pdf() {
    const quotePDF = await create_pdf(
      this.state.entries.filter((entry) => entry.active),
      this.options.path_pdfData
    );
    quotePDF.save("Angebot.pdf");
  }

  ////////////// Calc. ///////////////
  ////////////////////////////////////

  calc_totalTax(COST) {
    const opts = this.options;
    const salesTax = parseFloat(opts.salesTax);
    const salesTaxIncluded = parseInt(opts.salesTaxIncluded);
    //////
    return salesTaxIncluded ? COST - COST / (salesTax + 1) : COST * salesTax;
  }

  ////////////// Create //////////////
  ////////////////////////////////////

  create_itemList = create_itemList;
  create_subitemList = create_subitemList;
  create_item = create_item;
  create_subitem = create_subitem;

  ////////////// Updt. ///////////////
  ////////////////////////////////////

  updt_backendActiveState = updt_backendActiveState;

  //////////////////////////// Event handlers ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh_btn_pdfDwnld_click() {
    this.dwnld_pdf();
  }

  ////////////////////////// Eventbus Handlers ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  ebh_quote_itemCostUpdt() {
    this.setState({
      totalCost: calc_total(
        this.state.entries.filter((it) => it.active),
        "cost"
      ),
    });
  }

  ebh_quote_itemEffortUpdt() {
    this.setState({
      totalEffort: calc_total(
        this.state.entries.filter((it) => it.active),
        "effort"
      ),
    });
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes, RUN_withLogs = this.options.logs.runWithLogs) {
    const refs = this.ref;
    const state = this.state;
    const opts = this.options;

    const blockName = opts.blockName;

    /////////////// Logs ///////////////
    ////////////////////////////////////

    if (RUN_withLogs) {
      for (let c in changes) {
        log(blockName, ["state change", `${c} ⇒ ${changes[c]}`], `${blockName}_stateChange`);
      }
    }

    /////////////// Total cost/effort ///////////////
    ////////////////////////////////////

    if ("totalCost" in changes) {
      refs.totalCostLabel.innerHTML = create_totalLabel(state.totalCost, "cost");
      refs.totalTaxLabel.innerHTML = create_totalLabel(this.calc_totalTax(state.totalCost), "cost");
    }

    if ("totalEffort" in changes) {
      refs.totalEffortLabel.innerHTML = create_totalLabel(state.totalEffort, "effort");
    }
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default Quote;
