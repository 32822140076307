// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function create_itemList(ITEMELEMENTS) {
  return ITEMELEMENTS.map((itemEl) => this.create_item(itemEl));
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_itemList;
