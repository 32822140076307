// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function updt_backendActiveState(ITEM) {
  // Config. request...
  const request = new XMLHttpRequest();
  const data = new FormData();
  data.append("parentId", ITEM.is_subItem ? ITEM.id_parent : null);
  data.append("itemId", ITEM.id);
  data.append("itemContext", ITEM.context);
  data.append("updt_subitem", ITEM.is_subItem);

  // ...init. request...
  request.open("POST", `/de/${ITEM.route}`, true);

  // ...config. response listener...
  request.onreadystatechange = async () => {
    // If successful, get new item IDs & updt. them on the frontend...
    if (request.status === 200 && request.response) {
      // Get all item IDs...
      const request = await fetch(`/${this.options.route}.json`);
      const data = await request.json();

      // ...updt. IDs of all frontend items
      if (request.status === 200) {
        const frontendItems = this.state.entries;
        const newItemIDs = data.itemIDs;
        //////
        for (let i = 0; i < frontendItems.length; i++) {
          const item = frontendItems[i];
          item.setId = newItemIDs[i];
        }

        // ...re-enable interactions w/ item toggle button
        ITEM.setInteractions = true;
      }
    }
  };

  // ...send request
  request.send(data);
}

// ****************************************************************************** //
// ****************************************************************************** //

export default updt_backendActiveState;
