// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_textLine from "./print_textLine";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function render_footer(DOCUMENT, PAGENUMBER, PAGENUMBER_max) {
  const { margin, textHeight, pageSize } = config;
  print_textLine(DOCUMENT, `(${PAGENUMBER}/${PAGENUMBER_max})`, { x: margin, y: pageSize.h - (margin + textHeight) });
}

// ****************************************************************************** //
// ****************************************************************************** //

export default render_footer;
