// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function create_totalLabel(VALUE, TYPE = "cost") {
  const has_fourDigits = VALUE <= 9999.99 && VALUE >= 1000;
  const has_threeDigits = VALUE <= 999.99 && VALUE >= 100;
  const has_twoDigits = VALUE <= 99.99 && VALUE >= 10;
  const has_oneDigits = VALUE <= 9.99;
  //////
  const whitespace =
    TYPE === "cost"
      ? has_fourDigits
        ? 1
        : has_threeDigits
        ? 2
        : has_twoDigits
        ? 3
        : has_oneDigits
        ? 4
        : 0
      : TYPE === "effort"
      ? has_twoDigits
        ? 1
        : has_oneDigits
        ? 2
        : 0
      : 0;
  //////
  return `<span style="display: flex">
            ${"&nbsp;".repeat(whitespace)}${VALUE.toFixed(2)}
          </span>`;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_totalLabel;
