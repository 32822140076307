// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function create_subitemList(ID_parent, ITEMELEMENTS) {
  return ITEMELEMENTS.map((itemEl) => this.create_subitem(ID_parent, itemEl));
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_subitemList;
