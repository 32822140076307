// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_headline from "./print_headline";
import print_textLines from "./print_textLines";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function render_contentBlocks(DOCUMENT, BLOCKS, STARTPOSITION) {
  const { textHeight, textWidth } = config;
  const { x: x_start, y: y_start } = STARTPOSITION;
  //////
  let linesPrinted = 0;
  //////
  for (let i = 0; i < BLOCKS.length; i++) {
    const b = BLOCKS[i];
    const { text, type, align, mb } = b;
    //////
    const lines = DOCUMENT.splitTextToSize(text, textWidth);
    const position = { x: x_start, y: y_start + textHeight * linesPrinted };
    //////
    if (type == "heading") {
      print_headline(DOCUMENT, lines[0], position);
    } else if (type == "text") {
      print_textLines(DOCUMENT, lines, position, undefined, align);
    }
    //////
    linesPrinted += lines.length + mb;
  }
  //////
  return linesPrinted;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default render_contentBlocks;
