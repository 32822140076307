// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_textLine from "./print_textLine";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function print_textLines(DOCUMENT, LINES, STARTPOSITION, COLOR = [0, 0, 0], ALIGNMENT) {
  const { textHeight } = config;
  const { x, y } = STARTPOSITION;
  //////
  for (let i = 0; i < LINES.length; i++) {
    print_textLine(DOCUMENT, LINES[i], { x, y: y + textHeight * i }, COLOR, ALIGNMENT);
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default print_textLines;
