import eventbus from "gia/eventbus";

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import calc_total from "./calc_total";
import toggle_class from "../../../utilities/toggle_class";

// ****************************************************************************** //
// ****************************************************************************** //

function create_item(ITEMELEMENT) {
  const mainItem = ITEMELEMENT.querySelector("[data-main-item]");
  const id = mainItem.dataset.id;
  const subItems = ITEMELEMENT.querySelectorAll("[data-sub-item]");
  const is_active = mainItem.hasAttribute("data-is-active");
  const is_togglable = mainItem.hasAttribute("data-is-togglable");
  const toggleBtnEl = is_togglable ? document.getElementById(`toggleBtn_forItem_${id}`) : null;
  const costLabelEl = document.getElementById(`costLabel_forItem_${id}`);
  const effortLabelEl = document.getElementById(`effortLabel_forItem_${id}`);

  const costLabel = ($$$) =>
    `<span style="display: flex">
      ${$$$ <= 999.99 && $$$ > 99.99 ? "&nbsp;(" : "("}${$$$.toFixed(2)})
    </span>`;

  ///////////////////////////////////////////////////////
  // Set ID, active-state & config. getters/setters... //
  ///////////////////////////////////////////////////////

  const item = {
    context: mainItem.dataset.context,
    id: mainItem.dataset.id,
    title: mainItem.dataset.title,
    route: this.options.route,
    //////
    active: is_active ? true : false,
    interactions: true,
    is_subItem: false,
    //////
    el: mainItem,
    costLabelEl: costLabelEl,
    effortLabelEl: effortLabelEl,
    toggleButton: toggleBtnEl,
    //////
    subItems: this.create_subitemList(mainItem.dataset.id, [...subItems]),
    //////
    cost: parseFloat(mainItem.dataset.cost),
    effort: parseFloat(mainItem.dataset.effort),

    /////////////
    // Getters //
    /////////////

    get totalCost() {
      return calc_total(this.subItems, "cost");
    },

    get totalEffort() {
      return calc_total(this.subItems, "effort");
    },

    /////////////
    // Setters //
    /////////////

    set setId(ID_new) {
      // Updt. main item ID...
      this.id = ID_new;
      this.el.setAttribute("data-id", ID_new);

      // Updt. ID & parent ID of subitems...
      for (let i = 0; i < this.subItems.length; i++) {
        const subItem = this.subItems[i];
        subItem.setParentId = ID_new;
        subItem.setId = `${ID_new}_sub_${i}`;
      }
    },

    set setActive({ activeState }) {
      this.active = activeState;

      //////////////////////////////////////
      // Updt. item & button el. style... //
      //////////////////////////////////////

      toggle_class(this.el, "QuoteItem__item_state_inactive", null, !this.active);

      if (is_togglable) {
        toggle_class(this.toggleButton, "Button_state_active", null, this.active);
      }

      //////////////////////////////////////
      // Updt. quote total cost/effort... //
      //////////////////////////////////////

      eventbus.emit("quote_itemCostUpdt");
      eventbus.emit("quote_itemEffortUpdt");
    },

    set setInteractions(interactionsState) {
      this.interactions = interactionsState;
      toggle_class(this.toggleButton, "Button_state_disabled", null, !this.interactions);
    },

    set setCost(COST_new) {
      this.cost = COST_new;
      this.costLabelEl.innerHTML = costLabel(COST_new > 0 ? COST_new : this.totalCost);
    },

    set setEffort(EFFORT_new) {
      this.effort = EFFORT_new;
      this.effortLabelEl.innerHTML = costLabel(EFFORT_new > 0 ? EFFORT_new : this.totalEffort);
    },

    ///////////////
    // Init. f() //
    ///////////////

    init: function () {
      ////////////////////////////////////////////////////////
      // Config. active-toggle f() (toggle all subitems)... //
      ////////////////////////////////////////////////////////

      this.toggleAllSubItems = (UPDTPARENT) => {
        for (let subItem of this.subItems) {
          subItem.setActive = { activeState: this.active, updtParent: UPDTPARENT };
        }
      };

      /////////////////////////////////////////////
      // Config. updt.-parent f() on subitems... //
      /////////////////////////////////////////////

      for (let subItem of this.subItems) {
        subItem.updtParentActiveState = () => {
          const anySubItemsActive = this.subItems.some((it) => it.active);
          this.setActive = { activeState: anySubItemsActive, updtParent: false };
        };
        subItem.updtParentCostEffort = () => {
          const activeSubItems = this.subItems.filter((it) => it.active);
          //////
          if (activeSubItems.length == 0) {
            this.setCost = 0;
            this.setEffort = 0;
          } else {
            this.setCost = activeSubItems.length === 1 ? activeSubItems[0].cost : calc_total(activeSubItems, "cost");
            this.setEffort =
              activeSubItems.length === 1 ? activeSubItems[0].effort : calc_total(activeSubItems, "effort");
          }
        };
      }
    },
  };

  ///////////////////////////////////////////
  // Add click-handler to toggle button... //
  ///////////////////////////////////////////

  if (is_togglable) {
    toggleBtnEl.addEventListener("click", () => {
      item.setActive = { activeState: !item.active };

      // Updt. quote item's active state in backend...
      item.setInteractions = false;
      this.updt_backendActiveState(item);

      // If main item was toggled, toggle all subitems...
      if (!item.is_subItem) {
        item.toggleAllSubItems(false);
      }
    });
  }

  return item;
}

// ****************************************************************************** //
// ****************************************************************************** //

export default create_item;
