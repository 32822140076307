import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

// ********************************** PACKAGES ********************************** //
// ****************************************************************************** //

import Swiper, { Navigation } from "swiper";

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import log from "../../utilities/log";
import toggle_class from "../../utilities/toggle_class";
import toggle_interactions from "../../utilities/toggle_interactions";
import updt_styleProp from "../../utilities/updt_styleProp";

// ****************************************************************************** //
// ****************************************************************************** //

class Slider extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      inner: null,
      buttons: [],
      cursor: null,
    };
    //////
    this.options = {
      logs: {
        runWithLogs: false,
        logStyles: {
          default: "color: #000; background-color: #9999FF",
          success: "color: #000; background-color: #9900FF",
          stateChange: "color: #000; background-color: #9966FF",
        },
      },
      blockName: "Slider",
      ///
      nav: "defaultValue",
    };

    ///////// Event listeners //////////
    ////////////////////////////////////

    this.el_resize = this.eh_resize.bind(this);
    this.el_mouseMove = this.eh_mouseMove.bind(this);
    this.el_slideChange = this.eh_slideChange.bind(this);

    //////// Eventbus Listeners ////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    console.log(this.options);
    config.set("log", false);
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Note: global comp. are never unmounted

  unmount() {
    ////////////////////////////////
    // Unregister event listeners //
    ////////////////////////////////

    window.removeEventListener("resize", this.el_resize);

    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////

    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const refs = this.ref;
    const opts = this.options;
    //////
    this.init_states();
    this.init_eventbus();
    //////
    this.init_swiperInstance({ nav: opts.nav });
    //////
    if (opts.nav.type === "cursor") {
      this.init_cursor();
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.setState({
      // ...
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_events() {
    ///// Resize /////
    //////////////////

    window.addEventListener("resize", this.el_resize);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_mobile() {
    // ...
  }

  init_desktop() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_swiperInstance(
    CONFIG = {
      nav: {
        enabled: true,
      },
    }
  ) {
    /////////////////////////////// Config. ////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const config = {
      modules: [],
    };

    ////////////////////////////////////
    ////////////////////////////////////

    if (CONFIG.nav.enabled) {
      config.modules.push(Navigation);
      config.navigation = {
        nextEl: this.ref.buttons.find((b) => b.dataset.dir === "next"),
        prevEl: this.ref.buttons.find((b) => b.dataset.dir === "prev"),
      };
    }

    //////////////////////////// Instance init. ////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const instance = new Swiper(this.ref.inner, config);

    instance.on("slideChange", this.el_slideChange);

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    this.setState({
      swiper: {
        instance,
      },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_cursor() {
    this.element.addEventListener("mousemove", this.el_mouseMove);
  }

  //////////////////////////// Comp. Funtions ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  toggle_attr = (EL, ATTR, BOOL) => {
    EL.setAttribute(ATTR, BOOL ? "true" : "false");
  };

  /////////////// Get ////////////////
  ////////////////////////////////////

  // ...

  ////////////// Derive //////////////
  ////////////////////////////////////

  // ...

  ////////// Create/Delete ///////////
  ////////////////////////////////////

  // ...

  ////////////// Update //////////////
  ////////////////////////////////////

  // ...

  //////////////////////////// Event handlers ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh_resize() {
    console.log("EVENT: window has been resized...");
  }

  ////////////////////////////////////
  ////////////////////////////////////

  eh_mouseMove(EVENT) {
    ///////////////////////////////////////////////////
    // Set cursor position relative to comp. el. ... //
    ///////////////////////////////////////////////////

    const { clientX: mouseX, clientY: mouseY } = EVENT;
    const { left, top } = this.element.getBoundingClientRect();

    this.setState({
      cursor: {
        pos: {
          x: mouseX - left,
          y: mouseY - top,
        },
      },
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  eh_slideChange() {
    const instance = this.state.swiper.instance;
    //////
    this.setState({
      cursor: {
        index_curr: instance.realIndex + 1,
      },
    });
  }

  ////////////////////// Eventbus Handlers (global) //////////////////////
  ////////////////////////////////////////////////////////////////////////

  ebh_updt_namespace(args) {
    this.setState({
      namespace: args.namespace,
    });
  }

  ////////////////////// Eventbus Handlers (local) ///////////////////////
  ////////////////////////////////////////////////////////////////////////

  ebh_event(args) {
    console.log("handling event...", args);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes, RUN_withLogs = this.options.logs.runWithLogs) {
    const refs = this.ref;
    const state = this.state;
    const opts = this.options;

    const blockName = opts.blockName;

    /////////////// Logs ///////////////
    ////////////////////////////////////

    if (RUN_withLogs) {
      for (let c in changes) {
        log(blockName, ["state change", `${c} ⇒ ${changes[c]}`], `${blockName}_stateChange`);
      }
    }

    ////////////// cursor //////////////
    ////////////////////////////////////

    if (opts.nav.type === "cursor") {
      if ("cursor" in changes) {
        const cursorUpdt = state.cursor;
        const cursorEl = refs.cursor;
        //////
        if ("pos" in cursorUpdt) {
          const pos = cursorUpdt.pos;
          updt_styleProp(cursorEl, "--x", `${pos.x}px`);
          updt_styleProp(cursorEl, "--y", `${pos.y}px`);
        }
        //////
        if ("index_curr" in cursorUpdt) {
          const index_curr = cursorUpdt.index_curr;
          updt_styleProp(cursorEl, "--i", `'${index_curr}'`);
        }
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default Slider;
