// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function print_textLine(DOCUMENT, TEXT, POSITION, COLOR = [0, 0, 0], ALIGNMENT = "left") {
  const { pageSize, textHeight } = config;
  let { x, y } = POSITION;
  //////
  x = ALIGNMENT == "right" ? pageSize.w - x : x;
  y = y + textHeight;
  //////
  DOCUMENT.setTextColor(COLOR[0], COLOR[1], COLOR[2]);
  DOCUMENT.text(TEXT, x, y, { align: ALIGNMENT });
}

// ****************************************************************************** //
// ****************************************************************************** //

export default print_textLine;
