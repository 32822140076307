// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_textLine from "./print_textLine";

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function print_headline(DOCUMENT, TEXT, POSITION) {
  const { x, y } = POSITION;
  const { w } = DOCUMENT.getTextDimensions(`(${TEXT})`);

  /////////////////////////////////
  // ...add background & text... //
  /////////////////////////////////

  DOCUMENT.setFillColor(0, 0, 0);
  DOCUMENT.rect(x, y + 0.9, w, config.textHeight, "F");

  print_textLine(DOCUMENT, [`(${TEXT})`], { x, y }, [255, 255, 255]);
}

// ****************************************************************************** //
// ****************************************************************************** //

export default print_headline;
