import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

// ********************************** PACKAGES ********************************** //
// ****************************************************************************** //

// ...

// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import log from "../../utilities/log";
import toggle_class from "../../utilities/toggle_class";
import toggle_interactions from "../../utilities/toggle_interactions";

// ****************************************************************************** //
// ****************************************************************************** //

class Collapsible extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element) {
    super(element);
    //////
    this.ref = {
      button: null,
      content: null,
    };
    //////
    this.options = {
      logs: {
        runWithLogs: true,
        logStyles: {
          default: "color: #000; background-color: #9999FF",
          success: "color: #000; background-color: #9900FF",
          stateChange: "color: #000; background-color: #9966FF",
        },
      },
      blockName: "Collapsible",
    };

    ///////// Event listeners //////////
    ////////////////////////////////////

    this.el_button_click = this.eh_button_click.bind(this);

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    config.set("log", false);
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // Note: global comp. are never unmounted

  unmount() {
    ////////////////////////////////
    // Unregister event listeners //
    ////////////////////////////////
    // ...
    ///////////////////////////////////
    // Unregister eventbus listeners //
    ///////////////////////////////////
    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    const refs = this.ref;
    //////
    this.init_states();
    this.init_eventbus();
    this.init_events();
    //////
    this.init_button(refs.button);
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.setState({
      open: false,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_events() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_mobile() {
    // ...
  }

  init_desktop() {
    // ...
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_button(BUTTONEL) {
    BUTTONEL.addEventListener("click", this.el_button_click);
  }

  //////////////////////////// Comp. Funtions ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  /////////////// Get ////////////////
  ////////////////////////////////////

  // ...

  ////////////// Derive //////////////
  ////////////////////////////////////

  // ...

  ////////// Create/Delete ///////////
  ////////////////////////////////////

  // ...

  ////////////// Update //////////////
  ////////////////////////////////////

  // ...

  //////////////////////////// Event handlers ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  eh_button_click() {
    this.setState({
      open: !this.state.open,
    });
  }

  ////////////////////////// Eventbus Handlers ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  // ...

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes, RUN_withLogs = this.options.logs) {
    const refs = this.ref;
    const state = this.state;
    const opts = this.options;

    const blockName = opts.blockName;

    /////////////// Logs ///////////////
    ////////////////////////////////////

    if (RUN_withLogs) {
      for (let c in changes) {
        log(blockName, ["state change", `${c} ⇒ ${changes[c]}`], `${blockName}_stateChange`);
      }
    }

    /////////// Open/Closed ////////////
    ////////////////////////////////////

    if ("open" in changes) {
      toggle_class(refs.content, "hidden_full", null, !state.open);
      toggle_class(refs.button, "__button_state_open", blockName, state.open);
    }
  }
}

// ****************************************************************************** //
// ****************************************************************************** //

export default Collapsible;
