// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_headline from "./print_headline";
import print_textLines from "./print_textLines";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function get_longestString(STRINGS = []) {
  let longestLength = 0;
  let longestStr = null;
  //////
  for (const str of STRINGS) {
    const strLength = str.toString().length;
    if (strLength > longestLength) {
      longestLength = strLength;
      longestStr = str;
    }
  }
  //////
  return longestStr;
}

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function render_header(DOCUMENT, CONTENT) {
  const { margin, textHeight, pageSize } = config;
  const { contact, addressor } = CONTENT;

  //////////////////////////////////////
  // Print quote contact/recipient... //
  //////////////////////////////////////

  // Collect lines
  const name = contact["name"];
  const organisation = contact["organisation"];
  const street = `${contact["street"]} ${contact["houseNumber"]}`;
  const city = `${contact["zip"]} ${contact["city"]}`;
  const tel = contact["tel"];
  const email = contact["email"];
  const website = contact["website"];

  let lines = [name, organisation, street, city, "\n", tel, email, website];
  lines = lines.filter((l) => l.length > 0);

  print_headline(DOCUMENT, "an", { x: margin, y: margin });
  print_textLines(DOCUMENT, lines, { x: margin, y: margin + textHeight * 2 });

  /////////////////////////////////////
  // Print quote sender/addressor... //
  /////////////////////////////////////

  // Collect lines
  const address = addressor.address;
  const contactinfo = addressor.contactinfo;

  const name_addressor = address.name;
  const organisation_addressor = address.organisation;
  const street_addressor = `${address.street} ${address.housenumber}`;
  const city_addressor = `${address.zip} ${address.city}`;
  const tel_addressor = contactinfo.tel;
  const email_addressor = contactinfo.email;
  const website_addressor = contactinfo.website;

  let lines_addressor = [
    name_addressor,
    organisation_addressor,
    street_addressor,
    city_addressor,
    tel_addressor,
    email_addressor,
    website_addressor,
  ];
  lines_addressor = lines_addressor.filter((l) => l.length > 0);

  const width_widestLine = DOCUMENT.getTextDimensions(get_longestString(lines_addressor)).w;
  const x = pageSize.w - (width_widestLine + margin);
  print_headline(DOCUMENT, "von", { x, y: margin });
  print_textLines(DOCUMENT, lines_addressor, { x, y: margin + textHeight * 2 });
}

// ****************************************************************************** //
// ****************************************************************************** //

export default render_header;
