// ****************************************************************************** //
// ****************************************************************************** //

function updt_styleProp(EL, PROP, VALUE) {
  EL.style.setProperty(PROP, VALUE);
}

// ****************************************************************************** //
// ****************************************************************************** //

export default updt_styleProp;
