// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

import print_headline from "./print_headline";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function sumUp_totalEffort(ITEMS) {
  let totalEffort = 0;
  for (let it of ITEMS) {
    totalEffort += it.effort;
  }
  return totalEffort;
}

function sumUp_totalCost(ITEMS) {
  const totalEffort = sumUp_totalEffort(ITEMS);
  return totalEffort * config.rates.hour;
}

function calc_totalTax(ITEMS) {
  const totalCost = sumUp_totalCost(ITEMS);
  const totalTax = totalCost - totalCost / (config.rates.tax + 1);
  return totalTax.toFixed(2);
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function render_tableHeader(DOCUMENT, POSITION) {
  const { y } = POSITION;
  const { pageSize, margin } = config;
  const printSize = { w: pageSize.w - margin * 2 };
  //////
  print_headline(DOCUMENT, "Leistung", POSITION);
  print_headline(DOCUMENT, "h", { x: (printSize.w / 6) * 4 + DOCUMENT.getTextDimensions("(h)").w * 2, y: y });
  print_headline(DOCUMENT, "€", { x: (printSize.w / 6) * 5 + DOCUMENT.getTextDimensions("(€)").w * 2, y: y });
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function format_tableItems(ITEMS) {
  const items = [];
  //////
  for (let it of ITEMS) {
    it["is_subitem"] = false;
    items.push(it);
    //////
    if (it.subItems.length > 0) {
      for (let subIt of it.subItems) {
        subIt["is_subitem"] = true;
        items.push(subIt);
      }
    }
  }
  //////
  return items;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function format_tableBody(ITEMS) {
  const tableRows = [];
  //////
  for (let i = 0; i < ITEMS.length; i++) {
    const it = ITEMS[i];
    tableRows.push(format_tableRow(it, false));
    // Add subitems
    for (let subIt of it.subItems) {
      tableRows.push(format_tableRow(subIt, true));
    }
  }
  //////
  tableRows.push(["", "", ""]);
  tableRows.push(format_tableSummary(ITEMS));
  tableRows.push(format_tableTaxesSummary(ITEMS));
  //////
  return tableRows;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function format_tableRow(ITEM, IS_subItem) {
  const title = IS_subItem ? `— ${ITEM.title}` : ITEM.title;
  const effortInTime = ITEM.effort;
  const cost = effortInTime * config.rates.hour;
  //////
  return [title, `${effortInTime} h`, `${cost} €`];
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function format_tableSummary(ITEMS) {
  const totalEffort = sumUp_totalEffort(ITEMS);
  const totalCost = sumUp_totalCost(ITEMS);
  return ["Summe Angebot", `${totalEffort} h`, `${totalCost} €`];
}

function format_tableTaxesSummary(ITEMS) {
  const totalTax = calc_totalTax(ITEMS);
  return [`Darin enthaltene Mwst. (${config.rates.tax * 100}%)`, "", `${totalTax} €`];
}

// *********************************** CONFIG. ********************************** //
// ****************************************************************************** //

import config from "./config.json";

// ****************************************************************************** //
// ****************************************************************************** //

function render_table(DOCUMENT, ITEMS, POSITION) {
  const { x, y } = POSITION;
  const { pageSize, margin, fontSize, textHeight } = config;
  const printSize = { w: pageSize.w - margin * 2 };
  //////
  render_tableHeader(DOCUMENT, POSITION);
  //////
  DOCUMENT.autoTable({
    ////////// Sizing/Spacing //////////
    ////////////////////////////////////

    margin: { left: x, top: y + textHeight * 2.5 },
    tableWidth: pageSize.w - margin * 2,

    ////////////// Styles //////////////
    ////////////////////////////////////

    styles: {
      font: "Founders",
      fontSize: fontSize,
      cellPadding: { top: 0.666, right: 0, bottom: 0.55, left: 0 },
    },

    columnStyles: {
      0: { cellWidth: (printSize.w / 6) * 4 },
      1: { cellWidth: (printSize.w / 6) * 1 },
      2: { cellWidth: (printSize.w / 6) * 1 },
    },

    headStyles: {
      textColor: "#fff",
      fillColor: "#000",
      cellPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    },

    ///////////// Content //////////////
    ////////////////////////////////////

    body: format_tableBody(ITEMS),

    ////////////// Hooks ///////////////
    ////////////////////////////////////

    didParseCell: (data) => {
      /////////////////////////////////////////////////////
      // Set text color of subitem values (secondary)... //
      /////////////////////////////////////////////////////

      const rowIndex = data.row.index;
      const relatedItem = format_tableItems(ITEMS)[rowIndex];
      //////
      if (relatedItem && relatedItem.is_subitem) {
        const cells = data.row.cells;
        cells["1"].styles.textColor = [config.colors.text_secondary];
        cells["2"].styles.textColor = [config.colors.text_secondary];
      }
    },
  });
}

// ****************************************************************************** //
// ****************************************************************************** //

export default render_table;
